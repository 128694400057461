<style scoped>
.content {
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  padding: 10px;
  line-height: 30px;
  margin-bottom: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.content h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  /* height: 60px; */
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
}
.content_btn {
  padding: 8px 15px;
  border-radius: 3px;
  color: white;
  height: 35px;
  line-height: 20px;
  font-weight: bold;
  font-size: 12px;
}
.content_btn_del {
  background: #e05454;
}
.content_btn_edit {
  background: #7390ef;
}
.content_btn_view {
  background: green;
}
.content_btn_preview {
  background: #006180;
}
</style>

<template>
  <div>
    <common-top title="审核" :isShowSearch="false"></common-top>

    <div style="padding: 0 10px; margin-top: 10px" class="list">
      <div class="content" v-for="(data, index) in listCon" :key="index">
        <h3 style="text-align: left">{{ data.title }}</h3>

        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <p style="text-align: left">{{ data.created }}</p>
          <div
            class="content_btn content_btn_edit"
            style="margin-left: 10px"
            @click="toUrl('/zdMessageDetail', data.title)"
          >
            选择
          </div>
        </div>
      </div>
    </div>
    <loading-cmp v-if="showLoad"></loading-cmp>
    <load-more
      @refresh="onRefresh"
      :load-status="isLoadMore"
      :isShow="loadMoreShow"
    ></load-more>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>

    <div v-if="isConfirm && curCon">
      <confirm
        :title="`确认操作 `"
        @sure="oprationFun"
        @cancel="isConfirm = false"
      ></confirm>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
    <Modal v-model="isTime" fullscreen :closable="false">
      <div class="chooseDateFormItem">
        <div class="chooseDateFormItemTitle" style="margin-bottom: 5px">
          请选择发布日期：
        </div>
        <div class="detail">
          <DatePicker
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            :options="options3"
            :value="pickerValue"
            @on-change="setStartDateValue"
            placeholder="请选择时间"
            width="200px"
          ></DatePicker>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="chooseDate">确定</Button>
        <Button type="default" size="large" @click="cancelChoose">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
/* eslint-disable  */
import debug from "debug";
import Vue from "vue";
import moment from "moment";
import { wxConPost, getUserPm } from "../../api/httpApi";
import _ from "lodash";
import loadingCmp from "../../components/loadingCmp.vue";
import loadMore from "../../components/loadMoreData.vue";
import rnGaojianFooter from "../../components/rnGaojianFooter.vue";
import rnGaojian from "../../components/rnGaojian.vue";

import diaLogliu from "../../components/dialogLiu.vue";
import confirm from "../../components/commonConfirm.vue";

import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
let curDate = new Date();
const _d = debug("@pages:zdMessageReview");

moment.locale("zh-cn");

export default {
  name: "zdMessageReview",
  data () {
    return {
      showLoad: false,
      options3: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now();
        },
      },
      isUserPm: true,
      listCon: [],
      activeIndex: -1,
      pickerValue: "",
      isLoadMore: true,
      loadMoreShow: false,
      isConfirm: false,
      isTime: false,
      curCon: null,
      curIndex: -1,
      opration: "",

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },

  methods: {
    back () {
      this.$router.replace("/tougaoEditor");
    },
    async onRefresh () {
      const limit = this.$store.state.limit ? this.$store.state.limit : 10;
      console.log(this.listCon.length < limit, this.listCon.length, limit);
      const query = {
        column: "",
        limit: 20,
        searchValue: "",
        skip: this.listCon.length,
        sort: { created: -1 },
        viewStatus: false,
      };
      try {
        this.showLoad = true;
        const ret = await wxConPost(
          "/services/gttt-content-zd/dealComment/getComment",
          query
        );
        this.showLoad = false;
        const dataList = [];
        const data = ret.value;
        if (data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          let listData = [];
          for (const item of data) {
            listData = item;

            const date = new Date(listData.created)
            listData.created = date.toLocaleString();
            dataList.unshift(listData);
          }
          let arr1 = _.sortBy(dataList, "time");
          this.listCon = this.listCon.concat(arr1);

          this.loadMoreShow = true;
          this.isLoadMore = true;
        }
      } catch (error) {
        console.log("加载列表错误");
        console.log(error);
      }
    },
    async chooseDate () {
      console.log(this.pickerValue);
      try {
        const ret = await wxConPost(
          "/services/gttt-content-mgt/content/updateTime",
          {
            _cid: this.timeCid,
            time: this.pickerValue,
          }
        );
        this.diaLogFun("操作成功");
        this.getList();
        this.isTime = false;
      } catch (error) {
        this.diaLogFun("操作失败");
        this.isTime = false;
      }
    },
    setStartDateValue (date) {
      console.log(date);
      this.pickerValue = date;
    },
    async cancelChoose () {
      this.isTime = false;
    },
    // 获取栏目信息
    async getList () {
      const query = {
        column: "",
        limit: 20,
        searchValue: "",
        skip: 0,
        sort: { created: -1 },
        viewStatus: false,
      };


      try {
        this.showLoad = true;
        const ret = await wxConPost(
          "/services/gttt-content-zd/dealComment/getComment",
          query
        );
        this.showLoad = false;
        const data = ret.value;
        const dataList = [];
        if (data && data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          for (const item of data) {
            let listData = item;

            const date = new Date(listData.created)
            listData.created = date.toLocaleString();
            dataList.unshift(listData);
          }

          this.listCon = _.reverse(_.sortBy(dataList, "updated"));
          console.log("数据");
          console.log(this.listCon);

          // 显示加载
          this.loadMoreShow = true;
          // 显示加载
          this.isLoadMore = true;
        }
      } catch (error) { }
    },
    diaLogFun (msg) {
      this.dailogshow = true;
      this.dailogoptions.content = msg;
      this.dailogoptions.textColor = "white";
      setTimeout(() => {
        this.dailogshow = false;
      }, 2000);
    },

    toEdit (cid) {
      this.$router.push({
        path: "/tougaoEditor",
        query: {
          cid,
        },
      });
    },
    toUrl (url, title) {
      this.$router.push({
        path: url,
        query: {
          title: title,
        },
      });
    },
    openDialog (data) {
      this.isTime = true;
      console.log(data.updated);
      this.pickerValue = moment(data.updated).format("YYYY-MM-DD HH:mm");
      console.log(this.pickerValue);
      this.timeCid = data._cid;
    },
    showDialog (curCon, opration) {
      this.curCon = curCon;
      this.opration = opration;
      this.isConfirm = true;
    },
    oprationFun () {
      console.log("-------", this.opration);
      if (this.opration == "agree") {
        this.agree();
        return;
      }
      if (this.opration == "disagree") {
        this.disagree();
      }
    },

    // 审核通过
    async agree () {
      console.log("-obj------", this.curCon);
      try {
        const obj = this.curCon;
        console.log("-obj------111111", obj);
        // 设置提交审核状态
        await wxConPost("/services/gttt-content-zd/dealComment/viewPass", {
          id: obj.id,
          viewStatus: true,
        });
        this.diaLogFun("已将该内容审核为:通过");
        this.curCon = null;
        this.getList();
        this.curIndex = -1;
      } catch (e) {
        this.diaLogFun("操作异常");
      }
    },
    // 预览
    preview () { },
  },

  async created () {
    this.getList();
  },
  beforeCreate () {
    document.title = "站段留言审核发布";
  },

  components: {
    loadMore,
    rnGaojianFooter,
    rnGaojian,
    noPassCom,
    commonTop,
    diaLogliu,
    confirm,
    loadingCmp
  },
  filters: {
    dateFormat (time) {
      return moment(time).startOf("minutes").fromNow();
    },
  },
};
</script>
